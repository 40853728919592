/* montserrat-500 - latin_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: local(''),
         url('./montserrat-v18-latin_cyrillic-500.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  /* montserrat-600 - latin_cyrillic */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: local(''),
         url('./montserrat-v18-latin_cyrillic-600.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
    /* montserrat-700 - latin_cyrillic */
    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        src: local(''),
        url('./montserrat-v24-latin_cyrillic-700.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
    }
  /* montserrat-800 - latin_cyrillic */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: local(''),
         url('./montserrat-v18-latin_cyrillic-800.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }

  /* roboto-regular - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('./roboto-v29-latin_cyrillic-regular.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  /* roboto-500 - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''),
         url('./roboto-v29-latin_cyrillic-500.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  /* roboto-700 - latin_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url('./roboto-v29-latin_cyrillic-700.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }