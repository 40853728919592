@import 'antd-mobile/dist/antd-mobile.css';

body .am-action-sheet-wrap .am-action-sheet-content {
  margin-bottom: 40px;
}

body .am-action-sheet {
  background-color: transparent;
}

.am-action-sheet-wrap .am-action-sheet-button-list-item {
  border-radius: 8px;
  margin: 0 3px 8px 3px;
  background-color: rgba(255, 255, 255, 0.9);
  color: rgb(0, 122, 255);
  font-size: 20px;
  height: 57px;
  line-height: 56px;
}
.am-action-sheet-wrap .am-action-sheet-button-list-item.am-action-sheet-cancel-button {
  padding-top: 0;
}
.am-action-sheet-wrap .am-action-sheet-button-list-item .am-action-sheet-cancel-button-mask {
  display: none;
}
