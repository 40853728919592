@import 'antd/dist/antd.css';
@import './fonts/fonts.css';
@import '@vime/core/themes/default.css';

#root {
  min-height: 100%;
  height: 1px;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
}

.global-scrollblock {
  overflow: hidden;
}
